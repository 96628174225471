<template>
    <div class="">

        <div class="title">Skill</div>

        <div class="skill-content  mt-16 text-left">
           
            <div class="frontend flex gap-5">
            
              <div class="bg-white py-8 px-16 rounded shadow">
                  Vue Js
              </div>
              <div class="bg-white py-8 px-16 rounded shadow">
                  React Js
              </div>
              <div class="bg-white py-8 px-16 rounded shadow">
                  Html
              </div>

              <div class="bg-white py-8 px-16 rounded shadow">
                  Css 3
              </div>

              <div class="bg-white py-8 px-16 rounded shadow">
                  TailwindCss
              </div>
              <div class="bg-white py-8 px-16 rounded shadow">
                  Bootstrap
              </div>
        
            </div>

        </div>
        
    </div>
</template>

<script>

export default ({

    data() {
      return {

        }
    },

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }


})
</script>

<style scoped>

</style>
