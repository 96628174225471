<template>
        <div class="skill-content  mt-16 text-left font-semi-bold text-2xl">

            <div class="frontend">

                <div class="title">
                    <h1 class="text-4xl border-l-8 border-color pl-5">Frontend Skills</h1>
                </div>

                <div class="my-10 skills-div grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 shop gap-5">
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/js.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Javascript </h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/vue.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Vue Js</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/react.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2> React Js</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/html.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Html</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/css.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2> Css 3</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/tailwind.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>TailwindCss</h2>
                        </div>
                    </div>
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/bootstrap.jpg" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Bootstrap</h2>
                        </div>
                    </div>
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/bootswatch.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>bootswatch</h2>
                        </div>
                    </div>
                </div>
            </div>

             <div class="backend mt-10">

                <div class="title">
                    <h1 class="text-4xl border-l-8 border-color pl-5">Backend Skills</h1>
                </div>

                <div class="my-10 skills-div grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-5 shop gap-5">
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/php.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>PHP</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/mysql.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>MYSQl</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/laravel.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Laravel</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/json.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Json</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/api.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>REST API</h2>
                        </div>
                    </div>
                </div>
            </div>

             <div class="others">

                <div class="title">
                    <h1 class="text-4xl border-l-8 border-color pl-5">Other Skills</h1>
                </div>

                <div class="my-10 skills-div grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 shop gap-5">
                    <div class="skill-box hover:shadow-lg">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/github.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Github</h2>
                        </div>
                    </div>
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/wordpress.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Wordpress</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/wix.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Wix</h2>
                        </div>
                    </div>

                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/photoshop-colour.jpg" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Photoshop</h2>
                        </div>
                    </div>
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/after-effect.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>After Effect</h2>
                        </div>
                    </div>
                    <div class="skill-box">
                        <div class="content items-center flex gap-5 ">
                            <img src="./../../../assets/images/adobe-animate.png" alt="" srcset=""
                                class="h-14 w-14 rounded-full shadow p-1 bg-white">
                            <h2>Adobe Animate</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>

export default ({
        metaInfo: {
        title: 'Skills',
        },
    data() {
      return {

        }
    },


})
</script>

<style scoped>

img.skill-image{
  widows: 30px !important;  
}

</style>
